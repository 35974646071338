import Vue from 'vue'
import App from './App.vue'
import '@/assets/tailwind.css'
import '@/assets/common.css'
import '@/assets/animation.js'
import router from './router/'
import vuetify from './plugins/vuetify'
import jQuery from 'jquery'

Vue.config.productionTip = false

global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
