<template lang="pug">
  main
    section.pt-12
      .relative.sp
        .hexagon.absolute.z-10
          .hexagon--upper.w-screen
          .hexagon--content.w-screen.bg-white
            #sixth.fn_fadeIn.text-center.font-black.text-3xl.flex.justify-center.items-center
              h1.marker お客様の笑顔を創る
            #seventh.fn_fadeIn.text-center.font-bold.mt-3
              p 上限がない明るい未来を<br>どこまで創り出せるか
              p ともに挑戦し続けよう。
          .hexagon--bottom.w-screen
        .recruit--upper.w-screen.relative
          #first.fn_slideInTop.recruit-image--upper
          .flex.absolute.top-0
            #third.fn_slideInLeft.recruit-image--left
            #fifth.fn_slideInRight.recruit-image--right
        #second.fn_slideInBottom.recruit--bottom.w-screen.flex.justify-center.items-end
          img(src="../assets/recruit_logo@2x.png")#forth.fn_fadeIn.h-24
      .relative.pc.mb-98px.mt-30px
        .flex.w-full.absolute
            .left-side(class="w-3/6").relative
              .flex.justify-end.items-end
                .fn_slideInTop.recruit-image--upper-pc.absolute.top-0
                .fn_slideInBottom.recruit--bottom-pc.absolute.bottom-0
              .fn_slideInLeft.recruit-image--left-pc.absolute.top-0
            .relative(class="w-3/6").overflow-hidden
              .fn_slideInRight.recruit-image--right-pc
              .fn_fadeIn.recruit-image--logo-pc.rotate45.absolute
                img(src="../assets/pc/recruit_bg-logo.png").rotate-45
        .diamond.rotate45.overflow-hidden.relative
          .diamond__inner.rotate-45.absolute.text-center
            .fn_fadeIn.flex.justify-center.items-center.font-black.text-4xl.mt-24
              h1.marker お客様の笑顔を創る
            .fn_fadeIn.font-bold.mt-8.text-xl
              p 上限がない明るい未来を<br>どこまで創り出せるか
              p ともに挑戦し続けよう。
      .section-container
        .flex.mt-4.ml-2
          <router-link to="/">TOP</router-link>><router-link to="/recruit">RECRUIT</router-link>
      .section-container
        h1.fn_fadeIn.page-title.mt-10.mx-8.text-2xl.text-center.font-bold.relative 募集要項・エントリー
        p.fn_fadeIn.mt-8.mx-8.text-center
          | OWLYの採用情報を掲載いたします。
          br
          | 募集要項や選考フローについては
          br.pc
          | 職種ごとに詳細ページをご確認ください。
        .fn_fadeIn.job-container.flex.justify-center.items-center.mt-7.mx-2
          p ※現在募集している職種はありません。
</template>

<script>
import Animation from '../assets/animation'

export default {
  name: 'Recruit',
  components: {
    Animation
  },
  mounted () {
    Animation.animation()
  }
}

</script>

<style scoped lang="scss">
.recruit--upper {
  height: 40vh;
}

.recruit-image--upper {
  background-image: url('../assets/recruit_bg-1@2x.png');
  background-size: cover;
  background-position-y: bottom;
  height: 24vh;
}
.recruit-image--upper-pc {
  background-image: url('../assets/pc/recruit_bg-1.png');
  background-size: cover;
  background-position-x: right;
  height: 50%;
  width: 420px;
}
.recruit-image--left {
  background-image: url('../assets/recruit_bg-2@2x.png');
  background-size: cover;
  background-position-x: right;
  height: 44vh;
  width: 50vw;
}
.recruit-image--left-pc {
  background-image: url('../assets/pc/recruit_bg-2.png');
  background-size: cover;
  background-position-y: center;
  height: 428px;
  width:100%;
  margin-left: -211px;
}
.recruit-image--right {
  background-image: url('../assets/recruit_bg-3@2x.png');
  background-size: cover;
  background-position-x: left;
  height: 44vh;
  width: 50vw;
}
.recruit-image--right-pc {
  background-image: url('../assets/pc/recruit_bg-3.png');
  background-size: cover;
  background-position-x: left;
  height: 428px;
  width: 50vw;
}
.recruit--bottom {
  background-image: url('../assets/recruit_bg-4@2x.png');
  background-size: cover;
  height: 34.5vh;
  margin-top: 10vh;
}
.recruit--bottom-pc {
  background-image: url('../assets/pc/recruit_bg-4.png');
  background-size: cover;
  background-position-x: right;
  height: 50%;
  width: 420px;
}
.recruit-image--logo-pc {
  background: #69D2ED;
  top: 251px;
  left: 160px;
  height: 160px;
  width: 160px;
  img {
    margin-top: 34px;
  }
}
.hexagon {
  top: 21.5vh;
}
.hexagon--upper {
  height: 16vh;
  border-right: 50vw solid transparent;
  border-bottom: 16vh solid #fff;
  border-left: 50vw solid transparent;
}
.hexagon--content {
  height: 13vh;
}
.hexagon--bottom {
  height: 16vh;
  border-right: 50vw solid transparent;
  border-top: 16vh solid #fff;
  border-left: 50vw solid transparent;
}

.left-side {
  height: 428px;
}

.rotate45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate-45 {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.diamond {
  background: linear-gradient(45deg, rgba(0,0,0,0) 50%, rgba(255,255,255,1) 50%);
  top: 48px;
  left: 0;
  right: 0;
  width: 340px;
  height: 340px;
  margin: auto;
}
.diamond__inner {
  width: 340px;
  height: 310px;
}

.page-title::after {
  content:"";
  position: absolute;
  height: 4px;
  width: 72px;
  background: #036EB7;
  top: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.section-container {
	max-width: 1024px;
	margin: 0 auto;
}
.job-container {
  border: 2px dashed #036EB7;
  border-radius: 10px;
  height: 300px;
}

.mt-30px {
  margin-top: 30px;
}

.mb-98px {
  margin-bottom: 98px;
}
@media screen and (min-width: 620px) and (max-width: 1023px) {
  .recruit-image--upper {
		height: 40vh;
	}
}
@media screen and (min-width: 1024px) {
  .section-container {
		max-width: 1024px;
		margin: 0 auto;
	}
}

</style>
