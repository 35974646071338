function animation () {
  const options = {
    root: null,
    rootMargin: "0px 0px -80px",
    threshold: 0
  }

  const fn_slideInTop = document.querySelectorAll('.fn_slideInTop')
  const fn_slideInBottom = document.querySelectorAll('.fn_slideInBottom')
  const fn_slideInLeft = document.querySelectorAll('.fn_slideInLeft')
  const fn_slideInRight = document.querySelectorAll('.fn_slideInRight')
  const fn_maskBG = document.querySelectorAll('.fn_maskBG')
  const fn_fadeIn = document.querySelectorAll('.fn_fadeIn')

  fn_slideInTop.forEach((target) => onIntersect(target, options))
  fn_slideInBottom.forEach((target) => onIntersect(target, options))
  fn_slideInLeft.forEach((target) => onIntersect(target, options))
  fn_slideInRight.forEach((target) => onIntersect(target, options))
  fn_maskBG.forEach((target) => onIntersect(target, options))
  fn_fadeIn.forEach((target) => onIntersect(target, options))

  function addShowClass (entries) {
    for (const e of entries) {
      if (e.isIntersecting) {
        e.target.classList.add("show")
      }
    }
  }

  function onIntersect (target, options = {}) {
    const observer = new IntersectionObserver(addShowClass, options)
    observer.observe(target)
  }

  onIntersect();
}

export default { animation }
