<template lang="pug">
  ul#rotate-area.fn_slideInBottom(class="lg:flex lg:justify-between")
    li.mt-6(class="rotate-area relative mx-auto")
      img(src="../../assets/service-1@2x.png" alt="画像：ワンルーム経営").absolute.omote
      img(src="../../assets/service-1_ura.png" alt="画像：投資例").absolute.ura(class="lg:w-full")
    li.mt-6(class="rotate-area relative mx-auto")
      img(src="../../assets/service-2@2x.png" alt="画像：アパート経営").absolute.omote
      img(src="../../assets/service-2_ura.png" alt="画像：投資例").absolute.ura(class="lg:w-full")
    li.mt-6(class="rotate-area relative mx-auto")
      img(src="../../assets/service-3@2x.png" alt="画像：マンション投資").absolute.omote
      img(src="../../assets/service-3_ura.png" alt="画像：投資例").absolute.ura(class="lg:w-full")
</template>

<script>
export default {
  mounted: function () {
    const lists = Array.from(document.querySelectorAll("#rotate-area li img"));
    lists.forEach(img => {
      img.addEventListener("click", e => {
        const index = lists.findIndex(img => img === e.target);
        if ((index % 2) === 0) {
          lists[index].classList.toggle("omote");
          lists[index].classList.toggle("ura");
          lists[index + 1].classList.toggle("omote");
          lists[index + 1].classList.toggle("ura");
        } else {
          lists[index].classList.toggle("omote");
          lists[index].classList.toggle("ura");
          lists[index - 1].classList.toggle("omote");
          lists[index - 1].classList.toggle("ura");
        }
      });
    });
  }
}
</script>
<style scoped lang="scss">
#rotate-area {
    position: relative;
    min-height: 295px;
}
@media screen and (min-width: 1024px) {
  #rotate-area li {
    width: 320px !important;
    height: 383px !important;
    &:hover {
      cursor: pointer;
      width: 330px !important;
    }
  }
}
#rotate-area li {
  width: 273px;
  height: 328px;
  transition: 200ms ease-in;
  img {
    transition: 400ms ease-in;
  }
}

#rotate-area .omote {
    z-index: 1;
    transform: rotateY(0deg);
}
#rotate-area .ura {
    z-index: 0;
    transform: rotateY(180deg);
}
</style>
