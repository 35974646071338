import Vue from 'vue'
import Router from 'vue-router'
import TOP from '../views/Top.vue'
import Service from '../views/Service.vue'
import About from '../views/About.vue'
import Recruit from '../views/Recruit.vue'
import Contact from '../views/Contact.vue'
import Thanks from '../views/Thanks.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'TOP',
      component: TOP
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/service',
      name: 'Service',
      component: Service
    },
    {
      path: '/recruit',
      name: 'Recruit',
      component: Recruit
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/thanks',
      name: 'Thanks',
      component: Thanks
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
