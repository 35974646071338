<template lang="pug">
  header.header.fixed.w-full.px-2.py-2.flex.justify-between.items-center.z-50(class="lg:p-6")
    <router-link to="/">
      img(src="../assets/logo@2x.png" alt="OWLY").logo
    </router-link>
    .text-left.z-50
      .menu-open
        button(@click="toggle()")(title="メニュー").menu.text-white
          span Menu
      .overlay.fixed.w-full.h-screen.top-0.right-0
        div(class="lg:section-container lg:relative")
          button(@click="toggle()")(title="閉じる").menu-close.absolute.right-14(class="lg:right-20")
            <span></span>
          .flex.justify-center
            ul.text-white.text-4xl.fixed.ml-auto.mr-auto(class="top-1/4")
              li
                button(@click="toggle()")
                  <router-link to="/">TOP</router-link>
              li.mt-8
                button(@click="toggle()")
                  <router-link to="/about">ABOUT</router-link>
              li.mt-8
                button(@click="toggle()")
                  <router-link to="/service">SERVICE</router-link>
              li.mt-8
                button(@click="toggle()")
                  <router-link to="/recruit">RECRUIT</router-link>
              li.mt-8
                button(@click="toggle()")
                  <router-link to="/contact">CONTACT</router-link>
</template>

<script>
export default {
  methods: {
    toggle: function () {
      const menu = document.querySelectorAll('.overlay')
      menu[0].classList.toggle('open')
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nova+Round&display=swap');

.overlay {
  display: none;
  animation: fadeOut .5s linear 0s;
}

.overlay.open {
  display: block;
  animation: open .5s linear 0s;
  background: #121C4D;
}
@keyframes open{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes fadeOut{
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
.header {
  background-color: rgba(18,28,77);
  height: 48px;
}

.menu {
  font-family:'Nova Round';
  font-size: 22px;
  margin-right: 10px;
}

.menu-open button {
  visibility: visible;
  opacity: 1;
  -webkit-transition: .3s;
  transition: .3s;
}

.menu-open button::before {
  height: 2px;
  width: 40px;
  background-color: #fff;
  display: block;
  position: absolute;
  content: '';
  top: 9px;
  right: 48px;
  -webkit-transition: .3s;
  transition: .3s;
}

.menu-open button::after {
  height: 2px;
  width: 40px;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 37px;
  right:8px;
  content: '';
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.menu-open button.close {
  background-color: rgba(80, 80, 90, 0);
}

.menu-close {
  -webkit-transition: .3s;
  transition: .3s;
}

.menu-close:hover {
  opacity: 0.5;
}

.menu-close span::before {
  height: 3px;
  width: 40px;
  background-color: #fff;
  display: block;
  position: absolute;
  content: '';
  top: 40px;
  right: -40px;
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.menu-close span::after {
  height: 3px;
  width: 40px;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 40px;
  content: '';
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.overlay .router-link-exact-active {
  border-bottom: 2px solid #29E1FF;
}

.overlay ul li:hover {
  color: #29E1FF;
}

.logo {
  height: 24px;
}

@media screen and (max-width: 1023px) {
  .logo {
    height: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    height: 80px;
  }
  .logo {
    height: 42px;
  }
  .menu-open button::before {
    top: 20px;
    right: 64px;
  }
  .menu-open button::after {
    top: 58px;
    right:24px;
  }
  .menu-open button:hover::before {
    top: 36px;
    right: 90px;
    -webkit-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
  }
  .menu-open button:hover::after {
    top: 42px;
    right: 0px;
    -webkit-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
  }
  .menu-open button:hover {
    opacity: 0.5;
  }
}

</style>
