<template lang="pug">
<v-app id="app">
  main(class="sm:pt-12 lg:pt-28")
    .section-container
      .flex.mt-4.ml-2
        <router-link to="/">TOP</router-link>><router-link to="/contact">Contact</router-link>
    section.section-container
      h1.page-title.mt-10.mx-8.text-2xl.text-center.font-bold.relative CONTACT
      form.formrun.mx-auto.px-4(action="https://form.run/api/v1/r/hvzkaqeqbpdb5xk0wv2gkfcl" method="post")
        p.mt-8 お問い合わせ・相談予約はこちらからご連絡ください
        .question
          label.font-bold(data-required) お名前
          input(type="text" name="お名前"  data-formrun-required)
          .mt-2.text-red-600(data-formrun-show-if-error="お名前") お名前を正しく入力してください

        .question.mt-6
          label.font-bold(data-required) ふりがな
          input(type="text" name="ふりがな"  data-formrun-required)
          .mt-2.text-red-600(data-formrun-show-if-error="ふりがな") ふりがなを正しく入力してください

        .question.mt-6
          label.font-bold(data-required) メールアドレス
          input(type="text" name="メールアドレス" data-formrun-type="email" data-formrun-required)
          .mt-2.text-red-600(data-formrun-show-if-error="メールアドレス") メールアドレスを正しく入力してください

        .question.mt-6
          label.font-bold 電話番号
          input(type="tel" name="電話番号")

        .question.mt-6
          label.font-bold お問い合わせカテゴリ
          select(name="お問い合わせカテゴリ")
            option.hidden(value="") お問い合わせカテゴリ
            option(value="来店相談予約") 来店相談予約
            option(value="オンライン相談予約") オンライン相談予約
            option(value="その他お問い合わせ") その他お問い合わせ

        .question.mt-6
          label.font-bold 相談予約 第1希望日時
          input(type="datetime-local" name="相談予約 第1希望日時")

        .question.mt-6
          label.font-bold 相談予約 第2希望日時
          input(type="datetime-local" name="相談予約 第2希望日時")

        .question.mt-6
          label.font-bold 相談予約 第3希望日時
          input(type="datetime-local" name="相談予約 第3希望日時")

        .question.mt-6
          label.font-bold お問い合わせ内容
          textarea(name="お問い合わせ内容" rows="5")

        .text-center
          button.mt-6.py-2(type="submit" data-formrun-submitting-text="送信中...") 送信
</v-app>
</template>

<script>
export default {
  name: "Contact",
  mounted () {
    if (process.client) { this.loadFormrunScript() }
  },
  methods: {
    initFormrun () {
      window.Formrun._reset()
      window.Formrun.init('.formrun')
    },
    loadFormrunScript () {
      if (window.Formrun) {
        this.initFormrun()
        return
      }
      new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.addEventListener('load', resolve)
        script.addEventListener('error', reject)
        script.src = 'https://sdk.form.run/js/v2/formrun.js'
        document.head.appendChild(script)
      }).then(() => {
        this.initFormrun()
      })
        .catch(() => {
          console.log('err')
        })
    }
  }
}
</script>

<style scoped lang="scss">
.question {
  label {
    display: block;
  }
  label[data-required]::after {
    content: "*";
    color: red;
    margin-left: 4px;
  }
  input,
  textarea,
  select {
    border: 2px solid #036eb7;
    padding: 4px;
    width: 100%;
  }
}
.question select {
  -moz-appearance: auto;
  -webkit-appearance: auto;
}
.formrun {
  max-width: 640px;
  button {
    background-color: #036eb7 !important;
    color: #fff !important;
    width: 200px;
  }
}
</style>
