<template lang="pug">
  main
    img(src="../assets/mv-service@2x.png"  width="414" height="208" alt="画像：SERVICE").sp.w-screen
    img(src="../assets/pc/mv-service.png" alt="画像：SERVICE").pc.w-screen
    .section-container
      .flex.mt-4.ml-2
        <router-link to="/">TOP</router-link>><router-link to="/service">SERVICE</router-link>
    section.section-container
      div(class="lg:flex lg:flex-row-reverse")
        .fn_slideInBottom.mt-10
          h1.stripe.text-2xl.text-center.relative 投資用不動産の購入サポート
          p.mt-8.mx-8.text-lg.text-center(class="lg:text-left") OWLYは不動産投資に関する様々なノウハウでお客様の資産形成をサポートいたします。
        .fn_slideInBottom.mt-5.mx-10.sp
          img(src="../assets/features-image@2x.png" alt="画像：不動産投資をもっと身近に、より明るい未来を").w-screen(class="lg:w-full")
        .fn_slideInLeft.mt-5.mx-10.pc
          img(src="../assets/features-image@2x.png" alt="画像：不動産投資をもっと身近に、より明るい未来を").w-screen(class="lg:w-full")
      p.fn_maskBG.mt-4.mx-4.text-center(class="lg:mt-6 lg:w-full")
        | 投資用不動産の購入は、エリアやアセットタイプによりさまざまなリスクが伴います。
        br
        | 私たちOWLYは誰もが不動産投資を始められるように、
        br.pc
        | 少額からでも始められる「共同購入投資」や資金調達にお困りの方に心強い「融資付け支援」を強みに、
        br.pc
        | 情報力と分析力を駆使し、迅速な対応で成功へと導きます。
    section.fn_fadeIn.section-container.mt-6
      p.fn_fadeIn.feature-title.mx-4.relative.z-20(class="lg:-ml-4")
        img(src="../assets/2x/アセット 4@2x.png" alt="画像：不動産投資をもっと身近に、より明るい未来を")
      ul.feature-list.pt-4.px-2.pb-7(class="lg:flex lg:flex-wrap lg:pt-8")
        li.fn_slideInBottom.mt-4(class="lg:w-2/4 lg:pl-5")
          img(src="../assets/2x/アセット 1@2x.png" alt="画像：不動産投資をもっと身近に、より明るい未来を").w-screen
        li.fn_slideInBottom.mt-1(class="lg:w-2/4 lg:pl-5 lg:mt-3")
          img(src="../assets/2x/アセット 2@2x.png" alt="画像：不動産投資をもっと身近に、より明るい未来を").w-screen
        li.fn_slideInBottom.mt-1(class="lg:w-2/4 lg:pl-5")
          img(src="../assets/2x/アセット 3@2x.png" alt="画像：不動産投資をもっと身近に、より明るい未来を").w-screen
        li.fn_slideInBottom.mt-1(class="lg:w-2/4 lg:pl-5")
          img(src="../assets/2x/アセット 5@2x.png" alt="画像：不動産投資をもっと身近に、より明るい未来を").w-screen
    section.section-container.pt-9(class="lg:mx-20")
      h1.chapter.ml-3.text-xl 投資商材
      <ServiceList />
      p.mt-3.text-xs.text-center(class="lg:text-right") ※中層階～高層階マンション投資もご紹介可能です
      section.fn_slideInBottom.section-container.mt-8
        h1.chapter.mt-6.ml-3.text-xl 投資プラン
        ul
          li.mt-6
            img(src="../assets/investment-1.png" alt="画像：共同購入投資").sp
            img(src="../assets/investment-1-pc.png" alt="画像：共同購入投資").pc
          li.mt-6
            img(src="../assets/investment-2.png" alt="画像：銀行融資付け").sp
            img(src="../assets/investment-2-pc.png" alt="画像：銀行融資付け").pc
          li.mt-6
            img(src="../assets/investment-3.png" alt="画像：慎重派投資").sp
            img(src="../assets/investment-3-pc.png" alt="画像：慎重派投資").pc
        h2.my-12.second-heading.font-bold.text-2xl.text-center.relative サービス利用の流れ
        <FlowList />
        p.fn_maskBG.px-4.mt-6.text-center(class="lg:mt-7 lg:text-left lg:px-0 lg:w-full")
          |「不動産投資をしたいけど何から始めていいかわからない」、「投資する資金がない」など
          br.pc
          | お悩みの方、まずはご相談ください。
          br.pc
          | 店舗へのご来店のみならず、Web会議ツール（Zoom/Skype 等）を使ったオンラインでも対応可能です。
        <ButtonReservation />
</template>

<script>
import ServiceList from '../components/parts/ServiceList.vue'
import FlowList from '../components/parts/FlowList.vue'
import ButtonReservation from '../components/parts/ButtonReservation.vue'
import Animation from '../assets/animation'

export default {
  name: 'Service',
  components: {
    ServiceList,
    FlowList,
    ButtonReservation,
    Animation
  },
  mounted () {
    Animation.animation()
  }
};

</script>

<style scoped lang="scss">
.main-bg {
  background-color: rgba(18, 28, 77);
}

.stripe:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 290px;
  height: 8px;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    #036eb7,
    #036eb7 4px,
    #fff 4px,
    #fff 8px
  );
  background: repeating-linear-gradient(
    -45deg,
    #036eb7,
    #036eb7 4px,
    #fff 4px,
    #fff 8px
  );
}

.feature-title {
  top: 32px;
}

.feature-list {
  opacity: 0;
  animation-name: fadeInBG;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes fadeInBG {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    background: linear-gradient(#daeff8, #eaf0f3);
  }
}

.chapter::before {
  content: "";
  display: inline-block;
  width: 8px; /*線の長さ*/
  height: 1.25rem; /*線の太さ*/
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 3px;
  background-color: #036eb7;
}

.second-heading::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -15px; /*線の上下位置*/
  display: inline-block;
  width: 60px; /*線の長さ*/
  height: 5px; /*線の太さ*/
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); /*位置調整*/
  background-color: #f7b52c;
  border-radius: 2px;
}

@media screen and (min-width: 1024px) {
  .section-container {
    max-width: 1024px;
    margin: 0 auto;
  }
  .feature-title {
    top: 32px;
  }
  .see-more {
    width: 224px;
  }
}

@media screen and (max-width: 1023px) {
  .feature-title {
    top: 16px;
  }
}
</style>
