<template lang="pug">
  main
    img(src="../assets/about@2x.png" width="414" height="208" alt="画像：ABOUT").sp.w-screen
    img(src="../assets/pc/about.png" alt="画像：ABOUT").pc.w-screen
    .section-container
      .flex.mt-4.ml-2
        <router-link to="/">TOP</router-link>><router-link to="/about">ABOUT</router-link>
    section.section-container
      h1.fn_slideInBottom.page-title.mt-4.mx-8.text-2xl.text-center.font-bold.relative 誰もが未来に投資を
      p.fn_maskBG.mt-8.mx-8.text-center.text-lg(class="lg:w-full")
        | OWLYは不動産投資の豊富な知識と情報で
        br.hidden(class="lg:block")
        | お客様一人一人の資産状況に合わせた
        br.hidden(class="lg:block")
        | “最適な投資プラン” をご提案します。
      div(class="lg:flex")
        .description.mt-16.mx-4(class="lg:flex lg:flex-wrap lg:content-center lg:w-3/6")
          p 当社は、投資家の皆様に明るい未来と満足最大化を目的として、不動産投資に関する情報収集、分析を精緻に行い、投資機会を戦略的に発掘してまいります。
          p.mt-5 そして、不動産投資に関する助言やコンサルティング業務、不動産ファンドの組成・運用業務に関わるサービスを提供し、お客様に喜んでもらえることが、私たちの喜びです。
          p.mt-5 OWLYの由来にもなった幸運の動物”フクロウ”に因み、皆さまのもとに幸せを運び続ける会社でありたいと思っています。
        .overflow-hidden
          img(src="../assets/dummy@2x.png").fn_slideInRight.president(class="w-11/12 lg:mt-16")
    section.mt-6
      h1.text-2xl.text-center 会社概要
      dl.overview.mt-6(class="lg:mt-10")
        dt.pt-2.mx-4.font-corp 会社名
        dd.pb-2.mx-4.pl-8 株式会社OWLY
        dt.pt-2.mx-4.font-corp 住所
        dd.pb-2.mx-4.pl-8 〒101-0064 東京都千代田区猿楽町二丁目５番８号
        dt.pt-2.mx-4.font-corp 資本金
        dd.pb-2.mx-4.pl-8 10,000,000円
        dt.pt-2.mx-4.font-corp 従業員数
        dd.pb-2.mx-4.pl-8 20名
        dt.pt-2.mx-4.font-corp 代表者名
        dd.pb-2.mx-4.pl-8 test
        dt.pt-2.mx-4.font-corp 取引銀行
        dd.pb-2.mx-4.pl-8
          ul
            li bank
        dt.pt-2.mx-4.font-corp 不動産協会
        dd.pb-2.pl-8
          ul
            li 全国宅地建物取引業協会
            li 全日本不動産協会
      <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12966.991898658345!2d139.7454329!3d35.6585805!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x481a93f0d2a409dd!2z5p2x5Lqs44K_44Ov44O8!5e0!3m2!1sja!2sjp!4v1615030518821!5m2!1sja!2sjp" width="675" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</template>

<script>
import Animation from '../assets/animation'

export default {
  name: 'About',
  components: {
    Animation
  },
  data () {
    return {
      link: 1
    };
  },
  mounted () {
    Animation.animation()
  }
}
</script>

<style scoped lang="scss">
.description {
  background-image: url("../assets/about-bg@2x.png");
  background-size: contain;
  background-position-x: center;
}

.president {
  margin-left: auto;
  margin-right: auto;
}

.overview {
  border-top:1px solid #ccc ;
  margin-left: auto;
  margin-right: auto;
  max-width: 675px;
}

.overview dd {
  border-bottom:1px solid #ccc ;
}

.map {
  margin-left: auto;
  margin-right: auto;
  max-width: 675px;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .description {
    height: 440px;
  }
  .president {
    height: 440px;
  }
  .map {
    margin: 40px auto;
  }
  .section-container {
		max-width: 1024px;
		margin: 0 auto;
	}
}

@media screen and (max-width: 1023px) {
  .map {
    margin-top: 20px;
    padding: 0 16px;
  }
}
</style>
