<template lang="pug">
<v-app id="app">
  main(class="sm:pt-12 lg:pt-28")
    .section-container
      .flex.mt-4.ml-2
        <router-link to="/">TOP</router-link>><router-link to="/contact">Contact</router-link>
    section.section-container
      h1.mt-10.mx-8.text-2xl.text-center.font-bold.relative 送信完了
      p.mt-3.text-center お問い合わせを受け付けました。
      .text-center.mt-10
        <router-link to="/" class="py-3 px-14 btn">TOPへ戻る</router-link>
</v-app>
</template>

<script>
export default {
  name: "Thanks"
}
</script>

<style scoped lang="scss">
  .btn {
    background: #036EB6;
  }
  section a {
    color: #fff !important;
  }
</style>
