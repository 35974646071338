<template lang="pug">
  #wrapper.overflow-hidden
    main
      video(src="../assets/top.mp4" type="video/mp4" autoplay loop muted playsinline poster="../assets/main@2x.png").mt-12.w-full(class="lg:mt-0 lg:z-10")
    section.mt-11(class="lg:mt-36")
      .fn_slideInRight.intro-text-box.mr-8.main-bg.text-white(class="lg:flex-none lg:mr-0")
        .fn_slideInRight.intro-image-SP.relative(class="lg:hidden")
        p.fn_slideInLeft.intro-text.main-bg.pb-5.pl-5.pr-3(class="lg:w-3/6 lg:pt-12 lg:pb-10 lg:pl-16 lg:text-3xl lg:leading-relaxed lg:text-center lg:relative lg:z-40")
          | OWLYは不動産投資の豊富な知識と情報で、お客様一人一人の資産状況に合わせた “最適な投資プラン” をご提案します。
        .fn_slideInRight.intro-image-PC.relative
    section.section-container.pt-9(class="lg:mx-20")
      h1.fn_slideInBottom.mx-auto.py-5.border-4.border-gray-700.text-center.text-2xl.text-gray-700.font-bold(class="w-8/12 lg:w-1/6 lg:py-3 lg:ml-0")
        <router-link to="/service">SERVICE</router-link>
      img(src="../assets/banner-sp.png" alt="共同購入投資").sp.mt-5
      img(src="../assets/banner-pc.png" alt="共同購入投資").pc.mt-5
      p.fn_slideInBottom.px-4.mt-4.text-center(class="lg:mt-7 lg:text-left lg:px-0")
        | 私たちOWLYは誰もが不動産投資を始められるように
        br
        | 少額からでも始められる「共同購入投資」や資金調達にお困りの方に心強い「融資付け支援」を強みに
        br
        | 様々な方法で安心と価値を提供します。
      section.section-container
        <ServiceList />
        <ButtonSeeMore />
    section.section-container.pt-9(class="lg:mx-20")
      h1.fn_slideInBottom.mx-auto.py-5.border-4.border-gray-700.text-center.text-2xl.text-gray-700.font-bold(class="w-8/12 lg:w-1/6 lg:py-3 lg:ml-0") PROCESS
      <FlowList />
      p.fn_maskBG.mx-4.mt-8.text-center(class="lg:text-left lg:w-full")
        | 「不動産投資をしたいけど何から始めていいかわからない」、「投資する資金がない」など
        br
        | お悩みの方、まずはご相談ください。
        br
        | 店舗へのご来店のみならず、Web会議ツール（Zoom/Skype 等）を使ったオンラインでも対応可能です。
      <ButtonReservation />
    section.section-container(class="lg:mx-20")
      img(src="../assets/will@2x.png" alt="画像：#").fn_slideInLeft.sp.will-image.relative
      img(src="../assets/pc/will.png" alt="画像：#").fn_slideInLeft.pc.will-image.relative
      .fn_slideInLeft.will-text-box.pl-3.pb-6.ml-8.relative.text-white(class="lg:w-5/12")
        p.fn_slideInBottom.border-b-4.pt-5.pb-2.inline-block 不動産投資でお客様を笑顔に。
        p.fn_fadeIn.mt-4.pr-4
          | OWLYは、お客様の将来に対し安心を与える様な資産形成プランを、お客様のニーズにあった不動産を提案することで実現致します。
          br
          | 不動産投資が初めての方でも、安心してお任せいただける生涯のパートナーになる
</template>

<script>
import ServiceList from '../components/parts/ServiceList.vue'
import ButtonSeeMore from '../components/parts/ButtonSeeMore.vue'
import FlowList from '../components/parts/FlowList.vue'
import ButtonReservation from '../components/parts/ButtonReservation.vue'
import Animation from '../assets/animation'

export default {
  name: 'App',
  components: {
    ServiceList,
    ButtonSeeMore,
    FlowList,
    ButtonReservation,
    Animation
  },
  mounted () {
    Animation.animation()
  }
}
</script>

<style scoped lang="scss">
.main-bg {
	background-color: rgba(18,28,77);
}
.intro-image-SP {
  top: -20px;
  right: -30px;
  margin-left: auto;
  width: 260px;
  height: 162px;
  background-image: url("../assets/intro@2x.png");
  background-size:cover ;
  background-repeat: no-repeat;
}
.will-image {
	top: 35px;
  width: 85%;
}
.will-text-box {
  background-color: rgba(18,28,77);
}
@media screen and (min-width: 1024px) {
	.intro-section {
		margin-top: 160px;
	}
	.intro-text-box {
		margin-right: 175px;
		height: 410px;
	}
	.intro-text {
		top: 120px;
	}
	.intro-image-PC {
		top:-300px;
		left: 175px;
		margin-left: auto;
		width: 85%;
		height: 100%;
		background-image: url("../assets/pc/intro-min.jpg");
		background-size:cover ;
		background-position-y: bottom;
	}
  .will-text-box {
    margin: 0 0 0 auto;
    width: 492px;
  }
	.will-image {
		top: 100px;
	}
  .section-container {
		max-width: 1024px;
		margin: 0 auto;
	}
}
</style>
